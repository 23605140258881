
import { Component, InjectReactive as FromParentReactive, Mixins } from 'vue-property-decorator';

import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import HistoryTableMixin from '../../mixins/history-table.mixin';

let prevDay!: number | null;
let prevEl!: HTMLElement;

const formatter = new Intl
    .DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

let oldContainer!: HTMLElement;

@Component({
    extends: DayTooltipTemplate,
})
export default class PromotionsHistoryTooltip extends Mixins(HistoryTableMixin, DayTooltipTemplate) {
    @FromParentReactive('tooltipFocusElement')
    public element!: HTMLElement;

    @FromParentReactive('tooltipDay')
    public daysAgo!: number | null;

    @FromParentReactive('lastScanDay')
    public currentDay!: number;

    @FromParentReactive('filters')
    private filters!: {
        provider: string;
        program: string;
    };

    @FromParentReactive('mainHotelId')
    private mainHotelId!: number;

    @FromParentReactive('isDealProgram')
    public isDealProgram!: boolean;

    @FromParentReactive('hiddenGraphs')
    public hiddenGraphs!: { [hotelId: number]: boolean };

    public verticalOffset = 0;

    protected reversedChangingSide = true;

    public get date() {
        const lastScan = this.promotionsService.getLastScanDate(this.currentDay);
        if (!lastScan) return '';

        const daysAgoScan = new Date(lastScan);
        daysAgoScan.setDate(daysAgoScan.getDate() - this.daysAgo!);

        return formatter.format(daysAgoScan);
    }

    public get isActive() {
        return (!!this.daysAgo || this.daysAgo === 0) && !!this.element;
    }

    public get tableData() {
        const { currentDay, daysAgo } = this;
        const { mainHotelId, filters } = this;

        if (!daysAgo && daysAgo !== 0) return [];

        return this.getTableData(currentDay, daysAgo, mainHotelId, filters)
            .filter(row => !this.hiddenGraphs[row.id]);
    }

    mounted() {
        this.teleportToBody();
    }

    private teleportToBody() {
        const { tooltip } = this.$refs as { tooltip: CustomTooltip };
        if (!tooltip) return;

        oldContainer = tooltip.$el.parentNode as HTMLElement;

        document.body.appendChild(tooltip.$el);
    }

    private teleportBack() {
        const { tooltip } = this.$refs as { tooltip: CustomTooltip };
        if (!tooltip) return;

        oldContainer.appendChild(tooltip.$el);
    }

    beforeDestroy() {
        this.teleportBack();
    }

    updated() {
        if (
            prevEl !== this.element
            || prevDay !== this.daysAgo
        ) {
            prevDay = this.daysAgo;
            prevEl = this.element;

            this.updateTooltipPosition(this.element);
            this.updateVerticalOffset();
        }
    }

    private updateVerticalOffset() {
        const { tooltip } = this.$refs as { tooltip: CustomTooltip };
        if (!tooltip) return;

        const el = tooltip.getContainer();

        this.verticalOffset = el.offsetHeight / 2;
    }
}
