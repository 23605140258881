import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';

import ClusterPromotionsService, { ClusterPromotionsServiceS } from '@/modules/cluster/cluster-promotions.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';
import PromotionsHistoryService, { PromotionsHistoryServiceS } from '../promotion-history.service';

export interface HistoryFilters {
    program?: string;
    provider?: string;
}

@Component
export default class HistoryTableMixin extends Vue {
    @Inject(PromotionsServiceS) protected promotionsService!: PromotionsService;
    @Inject(PromotionsHistoryServiceS) protected promotionsHistoryService!: PromotionsHistoryService;
    @Inject(HotelsServiceS) protected hotelsService!: HotelsService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterPromotionsServiceS) private clusterPromotionsService!: ClusterPromotionsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    private get isClusterPage() {
        return this.$route.name!.includes('cluster.promotions.popup.history')
            || this.$route.name!.includes('chain.promotions.popup.history');
    }

    private getClusterCompetitors(mainHotelId: number) {
        if (!this.isClusterPage) return [];

        const compset = this.clusterService.getMainCompset(mainHotelId!);

        if (!compset) return [];

        return compset.competitors;
    }

    private getClusterCompsetId(mainHotelId: number) {
        if (!this.isClusterPage) return null;

        const compset = this.clusterService.getMainCompset(mainHotelId!);

        if (!compset) return null;

        return compset.id;
    }

    protected getTableData(currentDay: number, daysAgo: number, mainHotelId: number, filters: HistoryFilters) {
        if (!filters) return [];
        const program = filters.program || this.$route.params.program || null;
        const provider = filters.provider || this.$route.params.provider || null;

        const { data } = this.promotionsService;

        if (!data || !program || !provider) return [];

        const competitors = this.isClusterPage
            ? this.getClusterCompetitors(mainHotelId)
            : this.documentFiltersService.competitors;

        const compsetId = this.isClusterPage
            ? this.getClusterCompsetId(mainHotelId)
            : this.promotionsService.data?.id;

        const isDealProgram = program.includes('percentage');
        const palette = this.hotelsService.getHotelsGraphColor(compsetId);

        const hotels = [...competitors, mainHotelId];

        const list = hotels
            .map(hotelId => {
                const name = this.hotelsService
                    .getHotelName(hotelId) || hotelId;

                const programLabel = this.promotionsService
                    .getProgramLabel(provider, program);

                const isMain = hotelId === mainHotelId;
                const color = isMain
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : palette[hotelId];

                const programData = this.promotionsHistoryService
                    .getProgramData(currentDay, daysAgo, provider, hotelId, program);

                const programStatus = programData
                    ? programData.status
                    : false;

                const percent = programData && programStatus
                    ? programData.percentage
                    : 0;

                return {
                    id: hotelId,
                    name,
                    program: programLabel,
                    programStatus,
                    rank: 0,
                    percent,
                    color,
                    isMain,
                };
            });

        if (isDealProgram) {
            list.sort((a, b) => b.percent! - a.percent!);
            list.forEach((_, index) => {
                const row = list[index];
                list[index].rank = (row.programStatus && row.percent! > 0)
                    ? index + 1
                    : '' as unknown as number;
            });
        } else {
            list.reverse();
        }

        return list;
    }
}
