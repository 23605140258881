var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"promotions-history-table"},[_c('table',[_c('thead',[_c('tr',[_c('th'),_c('th',{attrs:{"width":"60%"}},[_vm._v("Hotel name")]),(_vm.isDealProgram)?_c('th',{domProps:{"textContent":_vm._s('Rank')}}):_vm._e(),_c('th',[_vm._v("Program")]),_c('th',[_vm._v("Status")]),(_vm.isDealProgram)?_c('th',{domProps:{"textContent":_vm._s('Percentage')}}):_vm._e()])]),_c('tbody',_vm._l((_vm.tableData),function(hotel){return _c('tr',{key:hotel.id,class:{
                    'promotions-history-table__row': true,
                    'promotions-history-table__row--main': hotel.isMain,
                    'promotions-history-table__row--hidden': _vm.hiddenGraphs[hotel.id],
                },on:{"click":function($event){return _vm.toggleGraph(hotel.id)}}},[_c('td',[_c('span',{class:{ skeleton: _vm.isLoading }},[_c('div',{class:{
                                'graph-legend': true,
                                'graph-legend--hidden': _vm.hiddenGraphs[hotel.id],
                            },style:({ '--indicator-color': hotel.color })})])]),_c('td',[_c('span',{class:{ skeleton: _vm.isLoading },domProps:{"textContent":_vm._s(hotel.name)}})]),(_vm.isDealProgram)?_c('td',[_c('span',{class:{ skeleton: _vm.isLoading },domProps:{"textContent":_vm._s(hotel.rank)}})]):_vm._e(),_c('td',[_c('span',{class:{ skeleton: _vm.isLoading },domProps:{"textContent":_vm._s(hotel.program)}})]),_c('td',[_c('span',{class:{ skeleton: _vm.isLoading }},[_c('i',{class:`icon-${hotel.programStatus ? 'v' : 'x'}-mark`})])]),(_vm.isDealProgram)?_c('td',[_c('span',{class:{ skeleton: _vm.isLoading },domProps:{"textContent":_vm._s(hotel.percent + '%')}})]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }